import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useNavigate } from "react-router-dom";

// Helper functions for sorting
function descendingComparator(a, b, orderBy) {
  if (orderBy === "complete") {
    // If 'complete' field is being sorted, treat true > false (sort `true` to come after `false`)
    return b[orderBy] === a[orderBy] ? 0 : b[orderBy] ? 1 : -1;
  }
  if (orderBy === "league") {
    // Sort league abbreviation (strings) lexicographically
    if (b[orderBy]?.abbreviation < a[orderBy]?.abbreviation) {
      return -1;
    }
    if (b[orderBy]?.abbreviation > a[orderBy]?.abbreviation) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "cutoff_date",
    numeric: false,
    disablePadding: true,
    label: "Previous Cube",
  },
  { id: "league", numeric: true, disablePadding: false, label: "Sport" },
  { id: "complete", numeric: true, disablePadding: false, label: "Cube" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white", fontWeight: "bold" }}
            style={{ color: "white" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={
                orderBy === headCell.id && order === "asc" ? "desc" : "asc"
              }
              onClick={createSortHandler(headCell.id)}
              sx={{
                color: "white",
                "&.Mui-active": { color: "white" },
              }}
              style={{ color: "white" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

export default function PreviousGameTable({ rows, searchText }) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("cutoff_date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(`Rows per page`, rows);
  const visibleRows = React.useMemo(() => {
    const filteredRows = rows.filter((row) =>
      row?.league?.abbreviation
        ?.toLowerCase()
        .includes(searchText.toLowerCase())
    );
    const sortedRows = stableSort(filteredRows, getComparator(order, orderBy));
    return sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [rows, order, orderBy, page, rowsPerPage, searchText]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, bgcolor: "transparent" }}>
        <TableContainer sx={{ color: "white" }} style={{ color: "white" }}>
          <Table
            sx={{ color: "white" }}
            style={{ color: "white" }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.map((row, index) => (
                <TableRow
                  hover
                  onClick={() =>
                    navigate("/headtohead", { state: { gameId: row.game_id } })
                  }
                  tabIndex={-1}
                  key={row.id}
                  sx={{
                    cursor: "pointer",
                    color: "white",
                    "&:hover": { backgroundColor: alpha("#ffffff", 0.1) },
                  }}
                  style={{ color: "white" }}
                >
                  <TableCell sx={{ color: "white" }} style={{ color: "white" }}>
                    <CalendarTodayIcon /> {row?.cutoff_date?.split("T")[0]}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ color: "white" }}
                    style={{ color: "white" }}
                  >
                    {row?.league?.abbreviation}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ color: "white" }}
                    style={{ color: "white" }}
                  >
                    {row?.complete ? "Complete" : "Inprogress"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 35]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: "white",
            "& .MuiTablePagination-selectIcon": { color: "white" },
          }}
          style={{ color: "white" }}
        />
      </Paper>
    </Box>
  );
}
